<template>
    <base-select-field v-on="$listeners"
                       :outlined="outlined"
                       :value="value"
                       :type="type"
                       :label="label"
                       :placeholder="placeholder"
                       :persistent-placeholder="persistentPlaceholder"
                       :hint="hint"
                       :hide-details="hideDetails === undefined ? !hint : hideDetails"
                       :persistent-hint="persistentHint"
                       :clearable="clearable"
                       :counter="counter"
                       :readonly="readonly"
                       :disabled="!disableDisabled && (disabled || isGlobalLoading)"
                       :loading="!disableLoading && (loading || isGlobalLoading)"
                       :full-width="fullWidth"
                       :rules="rules"
                       :prepend-icon="prependIcon"
                       :prepend-inner-icon="prependInnerIcon"
                       :append-icon="appendIcon"
                       :items="items"
                       :item-text="itemText"
                       :item-value="itemValue"
                       :return-object="returnObject === undefined ? !itemValue : returnObject"
                       :multiple="multiple"
                       @firstFocus.stop="firstFocus"
    >

        <template #item="{ item }">
            <slot name="item" :item="item">{{ !itemText ? item : item[itemText] }}</slot>
        </template>

        <template #selection="{ item, index }">
            <slot name="selection" :item="item" :index="index">{{ !itemText ? item : item[itemText] }}</slot>
        </template>

    </base-select-field>
</template>

<script>
    import BaseSelectField from '@/components/aaaGenerics/form/BaseSelectField';

    export default {
        name: 'BaseFormFieldApiSelect',
        components: {BaseSelectField},
        props: {
            //SelectField
            outlined: {type: Boolean, default: true},
            value: [String, Number, Boolean, Object, Array],
            type: String,
            label: String,
            placeholder: String,
            persistentPlaceholder: {type: Boolean, default: true},
            hint: String,
            hideDetails: {type: Boolean, default: undefined},
            persistentHint: {type: Boolean, default: true},
            clearable: {type: Boolean, default: true},
            counter: {type: Boolean, default: true},
            readonly: Boolean,
            disabled: Boolean,
            loading: Boolean,
            fullWidth: Boolean,
            rules: Array,
            prependIcon: String,
            prependInnerIcon: String,
            appendIcon: String,
            itemText: [String, Array, Function],
            itemValue: [String, Array, Function],
            returnObject: {type: Boolean, default: undefined},
            multiple: Boolean,
            //ApiSelectField
            modelsName: String,
            blockAutoStart: Boolean,
            disableSort: Boolean,
            disableDisabled: Boolean,
            disableLoading: Boolean
        },
        data: () => ({
            apiTimer: null
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            },
            items: function () {
                return this.$store.state.apiModule[this.modelsName.toLowerCase()];
            }
        },
        methods: {
            loadModel() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //GET del modello per ottenere il totalCount
                        _this.$store.dispatch('defaultRequest', {
                            api: 'GET_BASE',
                            paylod: {
                                modelsName: _this.modelsName.toLowerCase(),
                                options: {page: 1, itemsPerPage: 1}
                            },
                            doResponse: response => {

                                //GET del modello
                                _this.$store.dispatch('defaultRequest', {
                                    api: 'GET_BASE',
                                    paylod: {
                                        modelsName: _this.modelsName.toLowerCase(),
                                        options: {
                                            page: 1,
                                            itemsPerPage: response.totalCount,
                                            sortBy: _this.disableSort ? null : ['NOME']
                                        }
                                    },
                                    doResponse: response => _this.$emit('finish', response),
                                    doClose: () => _this.$emit('finish', [])
                                });

                            },
                            doClose: isOk => !isOk ? _this.$emit('finish', []) : null
                        });
                    }
                }

                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);
            },
            firstFocus() {
                if (this.blockAutoStart) {
                    this.loadModel();
                }
            }
        },
        created() {
            if (!this.blockAutoStart) {
                this.loadModel();
            }
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
            this.$store.commit('SET_' + this.modelsName.toUpperCase(), []);
        }
    }
</script>

<style scoped>

</style>
