import { render, staticRenderFns } from "./FormTipologia.vue?vue&type=template&id=78099e85&scoped=true&"
import script from "./FormTipologia.vue?vue&type=script&lang=js&"
export * from "./FormTipologia.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78099e85",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels})
