<template>
    <base-form models-name="tipologie"
               :tabs="tabs"
               :new-model="newModel"
               :is-resouces-loading="isLingueLoading"
               @endLoadModel="endLoadModel"
               @onSave="onSave"
    >

        <template #info="{model, isGlobalLoading}">
            <h-row justify="center">

                <h-col class="px-0" cols="9">
                    <base-select-field v-model="model.DISATTIVAZIONE_MANUALE"
                                       :items="[ {label: 'ATTIVO', value: false}, {label: 'DISATTIVATO', value: true} ]"
                                       item-text="label"
                                       item-value="value"
                                       label="Stato"
                                       placeholder="Seleziona se abilitare o disabilitare"
                                       hint="Decidi se abilitare o disabilitare manualmente la tipologia"
                                       :disabled="!!isGlobalLoading"
                                       prepend-icon="fa-toggle-on"
                    ></base-select-field>
                </h-col>

                <h-col v-show="false" class="px-0" cols="9">
                    <base-select-lingue @finish="isLingueLoading = false; panel = lingue.findIndex(l=>l.IS_DEFAULT);"
                    ></base-select-lingue>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-select-field v-model="model.IS_PERCORSO"
                                       :items="[{label: 'EVENTO', value: false}, {label: 'PERCORSO', value: true}]"
                                       item-text="label"
                                       item-value="value"
                                       label="Tipo"
                                       placeholder="Seleziona il tipo di tipologia"
                                       hint="Il tipo di tipologia determinerà anche il tipo di evento"
                                       :disabled="!!isGlobalLoading"
                                       :prepend-icon="'fa-' + (!model.IS_PERCORSO ? 'calendar-alt' : 'route')"
                                       :rules="[v => v !== null || 'Il tipo è obbligatorio!']"
                    ></base-select-field>
                </h-col>

                <h-col class="px-0" cols="9">
                    <v-expansion-panels v-model="panel">
                        <v-expansion-panel v-for="(l, index) in lingue" :key="index">

                            <v-expansion-panel-header>
                                <h-row>
                                    <h-col cols="8">
                                        <span>{{ l.NOME }}</span>
                                    </h-col>
                                    <h-col cols="4">
                                        <i v-if="l.IS_DEFAULT">(OBBLIGATORIO)</i>
                                        <i v-else-if="!getTesto(model, l).NOME">(VUOTO)</i>
                                    </h-col>
                                </h-row>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <h-row>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-text-field v-model.trim="getTesto(model, l).NOME"
                                                         label="Nome"
                                                         placeholder="Digita il nome della tipologia"
                                                         hint="Il nome serve a identificare la categoria degli eventi"
                                                         maxlength="500"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !getTesto(model, l).LNG_IS_DEFAULT || !!v || 'Il nome nella lingua ' + l.NOME + ' è obbligatorio!']"
                                                         prepend-icon="fa-signature"
                                        ></base-text-field>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-text-area-field v-model.trim="getTesto(model, l).DESCRIZIONE"
                                                              label="Descrizione"
                                                              placeholder="Digita la descrizione della tipologia"
                                                              hint="La descrizione non è obbligatoria, ma è utile a capire a cosa serve"
                                                              maxlength="500"
                                                              :disabled="!!isGlobalLoading"
                                                              prepend-icon="fa-clipboard"
                                        ></base-text-area-field>
                                    </h-col>

                                </h-row>
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>
                </h-col>

            </h-row>
        </template>

    </base-form>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseForm from '@/components/aaaProject/form/BaseForm';
    import BaseSelectLingue from '@/components/aaaProject/table/api/BaseSelectLingue';
    import BaseSelectField from '@/components/aaaGenerics/form/BaseSelectField';
    import BaseTextField from '@/components/aaaGenerics/form/BaseTextField';
    import BaseTextAreaField from '@/components/aaaGenerics/form/BaseTextAreaField';

    export default {
        name: 'FormTipologia',
        components: {
            HRow,
            HCol,
            BaseForm,
            BaseSelectLingue,
            BaseSelectField,
            BaseTextField,
            BaseTextAreaField
        },
        data: () => ({
            isLingueLoading: true,
            panel: null,
            tabs: [
                {name: 'info', title: 'INFO DI BASE', icon: 'fa-cube', showIf: true}
            ],
            newModel: {
                IS_PERCORSO: false,
                DISATTIVAZIONE_MANUALE: false,
                testi: []
            }
        }),
        computed: {
            lingue: function () {
                return this.$store.state.apiModule.lingue;
            }
        },
        methods: {
            endLoadModel(model) {

                const testi = model.testi;
                model.testi = [];
                for (const t of testi) {
                    model.testi.push({...t});
                }

            },
            onSave({model, save}) {

                model.testi = model.testi.filter(t => !!t.NOME);

                save(model);

            },
            getTesto(model, l) {

                let testo = model.testi.find(t => t.ID_LINGUA_FK === l.ID_REC);

                if (!testo) {
                    model.testi.push({ID_LINGUA_FK: l.ID_REC, LNG_IS_DEFAULT: l.IS_DEFAULT});
                    testo = this.getTesto(model, l);
                }

                return testo;

            }
        }
    }
</script>

<style scoped>

</style>
