<template>
    <base-form-field-api-select models-name="lingue"
                                v-model="idLinguaFk"
                                item-text="NOME"
                                item-value="ID_REC"
                                label="Lingua"
                                placeholder="Seleziona una lingua"
                                :clearable="false"
                                prepend-icon="fa-flag"
                                @input="$emit('input', $event)"
                                @finish="onFinish"
    ></base-form-field-api-select>
</template>

<script>
    import BaseFormFieldApiSelect from '@/components/aaaProject/form/BaseFormFieldApiSelect';

    export default {
        name: 'BaseSelectLingue',
        components: {BaseFormFieldApiSelect},
        data: () => ({
            idLinguaFk: undefined
        }),
        computed: {
            utente: function () {
                return this.$store.state.authModule.utente;
            }
        },
        methods: {
            onFinish(response) {

                if (response.totalCount) {

                    this.idLinguaFk = this.utente.ID_LINGUA_FK;

                    this.$emit('input', this.idLinguaFk);

                }

                this.$emit('finish', response);

            }
        }
    }
</script>

<style scoped>

</style>
